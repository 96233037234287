import React from 'react'
import {z} from 'zod'
import {v4 as uuidv4} from 'uuid'
import {toast} from 'react-toastify'

import {useSelector} from '../../../store'

import {JsonEditor} from '../../JsonEditor'
import {Tabs, TabsContent, TabsList, TabsTriggerAlt} from '../../ui-primitives/tabs'
import {Cross2Icon, SymbolIcon, PlusIcon, Link2Icon, LinkBreak2Icon, PlayIcon} from '@radix-ui/react-icons'
import {Label} from '../../ui-primitives/label'
import {Input} from '../../ui-primitives/input'
import {Switch} from '../../ui-primitives/switch'
import {Button} from '../../ui-primitives/button'
import {InputWithSuggestions} from '../../ui-primitives/input-with-suggestions'

import {DndContext, PointerSensor, UniqueIdentifier, useSensor, useSensors} from '@dnd-kit/core'
import {arrayMove, SortableContext} from '@dnd-kit/sortable'
import {SortableItem} from '../../sortable/sortable-item'
import {Tooltip} from '@mui/material'
import {parseSparqlParams} from '../../../utils/parse-sparql-params'

export type ColumnConfigs = z.infer<typeof stateSchema>
type ColumnConfig = ColumnConfigs[number]

const dataColumnConfigSchema = z.object({
  id: z.string(),
  label: z.string(),
  publishTopic: z.optional(z.string()),
  filterable: z.boolean(),
  sortable: z.boolean(),
  width: z.string(),
})

const startRuleActionSchema = z.object({
  type: z.literal('start-rule'),
  startRule: z.string(),
  parameters: z.optional(z.record(z.string(), z.string())),
  timestampVariable: z.optional(z.string()),
})

const publishAppVarSchema = z.object({
  type: z.literal('publish'),
  topic: z.string(),
  value: z.string(),
  hideIfEmptyValue: z.optional(z.boolean()),
})

const buttonsSchema = z.array(z.object({
  icon: z.optional(z.string()),
  label: z.optional(z.string()),
  actions: z.optional(z.array(z.union([
    startRuleActionSchema,
    publishAppVarSchema
  ]))),
  buttons: z.optional(z.array(z.object({
    label: z.string(),
    icon: z.string(),
    actions: z.array(z.union([
      startRuleActionSchema,
      publishAppVarSchema,
    ]))
  }))),
}))

const picklistSchema = z.object({
  type: z.union([z.literal('radio'), z.literal('select')]),
  itemsJSON: z.string(),
  currentItem: z.optional(z.string()),
  actions: z.array(z.union([
    startRuleActionSchema,
    publishAppVarSchema,
  ]))
})

const actionColumnConfigSchema = z.intersection(
  z.object({
    id: z.string().nonempty(),
    label: z.string(),
    width: z.string(),
  }),
  z.union([
    z.object({buttons: buttonsSchema}),
    z.object({picklist: picklistSchema}),
  ])
)

const columnConfigSchema = z.union([dataColumnConfigSchema, actionColumnConfigSchema])

const stateSchema = z.array(columnConfigSchema)

function parseColumn(column: unknown) {
  return {
    dataColumn: dataColumnConfigSchema.safeParse(column),
    actionColumn: actionColumnConfigSchema.safeParse(column),
  }
}

export function TablePlusPlusColumnConfig(props: {
  name: string, columnConfigs: ColumnConfigs,
  onChange: (name: string, value: ColumnConfigs) => void
}) {
  const [sparqlVarNames, setSparqlVarNames] = React.useState<string[]>([])
  const [selectVarNames, setSelectVarNames] = React.useState<string[]>([])
  const [tabId, setTabId] = React.useState<string>(props.columnConfigs[0]?.id)

  const dataYasqe = useSelector(({currentViewer: {activeYasqes}}) => {
    if (activeYasqes.length === 0) console.error('no sparql editor window was found')
    if (activeYasqes.length > 1) console.error('could not identify which sparql-editor window is used for data-querying')
    return activeYasqes.length === 1 ? activeYasqes[0] : null
  })

  React.useEffect(() => {
    if (!dataYasqe) return
    const updateSPARQLVarNames = () => {
      const sparqlVarNames = dataYasqe.getVariablesFromQuery().map(s => s.slice(1))
      const selectVarNames = parseSparqlParams(dataYasqe.getValueWithoutComments())
      setSparqlVarNames(sparqlVarNames)
      setSelectVarNames(selectVarNames)
    }
    updateSPARQLVarNames()
    dataYasqe.on('blur', updateSPARQLVarNames)
  }, [dataYasqe, setSparqlVarNames])

  // these make it reasonably easy to trigger onClick events on drag-and-drop items
  const reasonablyClickableSensors = useSensors(useSensor(PointerSensor, {activationConstraint: {distance: 8}}))

  const isLinked = (column: ColumnConfig) => selectVarNames.includes(column.id)
  const isActionColumn = (column: ColumnConfig) => actionColumnConfigSchema.safeParse(column).success

  const updateColumn = (targetColumn: ColumnConfig, newColumnConfig: ColumnConfig) =>
    props.onChange(props.name,
                   props.columnConfigs.map(column => column !== targetColumn ? column : newColumnConfig))


  const dataVarNames = sparqlVarNames.filter(s => !s.includes('_'))
  const dataSelectVarNames = selectVarNames.filter(s => !s.includes('_'))

  const unlinkedVarNames = dataVarNames.filter(varName =>
    !props.columnConfigs.some(column => column.id === varName))
  const unlinkedSelectVarNames = dataSelectVarNames.filter(varName =>
    !props.columnConfigs.some(column => column.id === varName))

  const addDataColumn = () => {
    const newColumn = { width: 'auto', filterable: true, sortable: true,
      id: unlinkedSelectVarNames?.[0] ?? `data-${Date.now()}`,
      label: unlinkedSelectVarNames?.[0] ?? ''}
    props.onChange(props.name, [...props.columnConfigs, newColumn])
    setTabId(newColumn.id)
  }

  const addActionColumn = () => {
    const newColumn = createDefaultActionColumnConfig()
    props.onChange(props.name, [...props.columnConfigs, newColumn])
    setTabId(newColumn.id)
  }

  const removeColumn = (targetColumn: ColumnConfig) =>
    props.onChange(props.name, props.columnConfigs.filter(column => column !== targetColumn))

  const autoFillColumns = () => {
    if (!unlinkedSelectVarNames.length) return
    const newColumns = unlinkedSelectVarNames.map(name =>
      ({id: name, label: name, width: 'auto', filterable: true, sortable: true}))
    props.onChange(props.name, [...props.columnConfigs, ...newColumns])
    setTabId(newColumns[0].id)
  }

  const reorder = (activeId: UniqueIdentifier, overId: UniqueIdentifier) => {
    const oldIdx = props.columnConfigs.findIndex(c => c.id === activeId)
    const newIdx = props.columnConfigs.findIndex(c => c.id === overId)
    const newColumns = arrayMove(props.columnConfigs, oldIdx, newIdx)
    props.onChange(props.name, newColumns)
  }

  return (
    <div  className="w-full p-2 border-2 rounded-lg bg-muted text-foreground shadow-lg" style={{
      fontFamily: '"Exo 2 Variable", sans-serif'
    }}>
      <div className="flex w-full gap-2 pb-2">
        <Tooltip title={dataSelectVarNames.length === 0 ? "Autofill with SELECT * is not supported" : undefined}>
          <span>{/* need this <span> for MUI tooltip otherwise it won't show the tooltip on a disabled button -_-*/}
            <Button disabled={dataSelectVarNames.length === 0}
                      onClick={autoFillColumns}
                      className="gap-2">
              <SymbolIcon />
              <span>Auto fill</span>
            </Button>
          </span>
        </Tooltip>
        <Button onClick={addDataColumn} className="gap-2"><PlusIcon /><span>Add sparql column</span></Button>
        <Button onClick={addActionColumn} className="gap-2"><PlusIcon /><span>Add actions column</span></Button>
      </div>

      <div className="shadow rounded-lg">
        <Tabs value={tabId} onValueChange={setTabId}>
          <DndContext sensors={reasonablyClickableSensors} onDragEnd={(e) => {
            if (!e.active || !e.over) return
            reorder(e.active.id, e.over.id)
          }}>
            <TabsList className="w-full flex flex-row flex-wrap justify-start p-0 h-auto mx-[-1px] bg-transparent">
              <SortableContext items={props.columnConfigs.map(c => c.id)}>{props.columnConfigs.map(column => (
                <SortableItem id={column.id}>
                  <TabsTriggerAlt className="!pr-2 gap-2 [&>button]:hover:opacity-100" value={column.id} aria-label={column.label}>
                    {isActionColumn(column)? <PlayIcon/> : isLinked(column)? <Link2Icon/> : <LinkBreak2Icon/>} {column.label}
                    <Button variant="ghost"
                            aria-label='Remove column'
                            className="rounded size-5 p-1 hover:bg-slate-300 opacity-50 transition-opacity "
                            onPointerDown={e => e.stopPropagation() /* stop radix from selecting the tab */}
                            onMouseDown={e => e.stopPropagation()  /* stop radix from selecting the tab */}
                            onFocus={e => e.stopPropagation() /* stop radix from selecting the tab */}
                            onClick={e => {
                              e.stopPropagation()  /* stop radix from selecting the tab */
                              removeColumn(column)
                            }}>
                      <Cross2Icon />
                    </Button>
                  </TabsTriggerAlt>
                </SortableItem>
              ))}</SortableContext>
            </TabsList>
          </DndContext>
          {props.columnConfigs.map(column => {
            const parsed = parseColumn(column)

            if (parsed.dataColumn.success && !parsed.actionColumn.success) return (
              <TabsContent value={parsed.dataColumn.data.id} className="grid w-full items-center gap-4 p-4 mt-0 bg-background rounded-lg">
                <Label className="grid w-full gap-1.5">
                  <span>SPARQL variable name</span>
                  <InputWithSuggestions
                    placeholder={`${isLinked(column) ? '🔗' : '⛓️‍💥'} ${parsed.dataColumn.data.id}`}
                    selected={parsed.dataColumn.data.id}
                    select={(id: string) => {
                      updateColumn(column, {...column, id})
                      setTabId(id)
                    }}
                    suggestions={unlinkedVarNames}
                    noSuggestionsPlaceholder={
                      dataVarNames.length === 0
                        ? "current query does not contain any sparql identifiers"
                        : "there are no unused sparql identifiers remaining"}
                  />
                </Label>
                <Label className="grid w-full gap-1.5">
                  <span>Header label</span>
                  <Input type="text" value={parsed.dataColumn.data.label}
                    onChange={e => updateColumn(column, {...column, label: e.target.value})} />
                </Label>
                <Label className="grid w-full gap-1.5">
                  <span>Topic to publish to when clicked with <code>_URI</code> modifier</span>
                  <Input type="text" value={parsed.dataColumn.data.publishTopic}
                    onChange={e => updateColumn(column, {...column, publishTopic: e.target.value || undefined})} />
                </Label>
                <Label className="grid w-full gap-1.5">
                  <span>Column width</span>
                  <Input type="text" value={parsed.dataColumn.data.width}
                    onChange={e => updateColumn(column, {...column, width: e.target.value})} />
                </Label>
                <Label className="flex items-center space-x-2">
                  <Switch checked={parsed.dataColumn.data.filterable}
                    onCheckedChange={v => updateColumn(column, {...column, filterable: v})} />
                  <span>Filter features</span></Label>
                <Label className="flex items-center space-x-2">
                  <Switch checked={parsed.dataColumn.data.sortable}
                    onCheckedChange={v => updateColumn(column, {...column, sortable: v})} />
                  <span>Sort features</span></Label>
              </TabsContent>
            )

            if (parsed.actionColumn.success && !parsed.dataColumn.success) return (
              <TabsContent value={column.id} className="grid w-full items-center gap-4 p-4 mt-0 bg-background rounded-lg">
                <JsonEditor name={props.name} value={column}
                  onValueChange={value => {
                    try {
                      const columnConfig = actionColumnConfigSchema.parse(value)
                      updateColumn(column, columnConfig)
                      setTabId(columnConfig.id)
                    } catch(e) {
                      console.error(e)
                      toast('could not load column configs correctly, see dev console')
                    }
                  }} />
              </TabsContent>
            )

            if (!parsed.dataColumn.success) console.error(parsed.dataColumn.error)
            if (!parsed.actionColumn.success) console.error(parsed.actionColumn.error)
            return (
              <TabsContent value={column.id} className="grid w-full items-center gap-4 p-4 mt-0 bg-background rounded-lg">
                  This column is configured in an unknown format, see browser dev console for more info.
                  It can only be a data-column or an action-column.
                  Please fix using json-editor below.
                  For support: create an issue.
                <JsonEditor name={props.name} value={column}
                  onValueChange={value => {
                    try {
                      const parsedInput = parseColumn(value)
                      if (parsedInput.actionColumn.success && parsedInput.dataColumn.success) {
                        throw new Error(
                          `"${column.label}" parsed as both data-column and action-column, whilst it can only be either of the two.` +
                          '\n\ndata-column\n\n' +
                          JSON.stringify(parsedInput.dataColumn.data) +
                          '\n\naction-column\n\n' +
                          JSON.stringify(parsedInput.actionColumn.data)
                        )
                      }
                      if (!parsedInput.actionColumn.success && !parsedInput.dataColumn.success) {
                        throw new Error(
                          `"${column.label}" could not be parsed as either data-column or action-column` +
                          '\n\ndata-column\n\n' +
                          parsedInput.dataColumn.error +
                          '\n\naction-column\n\n' +
                          parsedInput.actionColumn.error
                        )
                      }
                      if (parsedInput.dataColumn.success) updateColumn(column, parsedInput.dataColumn.data)
                      if (parsedInput.actionColumn.success) updateColumn(column, parsedInput.actionColumn.data)
                      throw new Error('unhandled case')
                    } catch(e) {
                      console.error(e)
                      toast('could not load column configs correctly, see dev console')
                    }
                  }} />
                </TabsContent>
            )

          })}
        </Tabs>
      </div>
    </div>
  )
}

function createDefaultActionColumnConfig(): ColumnConfig {
  return {
    id: uuidv4(),
    label: 'Actions',
    width: 'auto',
    buttons: [{
      icon: "fa-star",
      label: "Run a rule!",
      actions: [{
        type: "start-rule",
        startRule: "my-rule-tag",
        parameters: {
          "myRuleParam": "{{myRuleParamValue}}"
        },
        timestampVariable: "myTimestampAfterRuleCompletes"
      }]
    },{
      icon: "radix-component-instance",
      label: "Pubsub something!",
      actions: [{
        type: "publish",
        topic: "myTopic",
        value: "{{columnVariableOrPubsubVariable}}"
      }]
    }]
  }
}
