import React from 'react'
import PublishUtils from '../../../helpers/PublishUtils'

export const configOptions = [
  {
    name: 'query',
    type: 'yasgui',
    label: 'Query where 3 variables are expected in the following order (can choose name yourself): label, uri, parentUri; where parentUri does not exist for root nodes',
  },
  {
    name: 'selectedItemsTopic',
    type: 'text',
    label: 'Topic to publish selected items to.'
  },
  PublishUtils.getMultipleValueFormatOptions(),
  {
    name: 'initiallyFoldedItems',
    type: 'boolean',
    label: 'initially fold all tree-items',
  },
  {
    name: 'initiallySelectAllItems',
    type: 'boolean',
    label: 'initially select all tree-items',
  },
  {
    name: 'labelSelectOrDeselectAll',
    type: 'text',
    label: 'label to select or deselect all (no checkbox if empty)'
  },
]

export const TreePlusPlusDescription = {
  name: 'Tree++',
  component: React.lazy(() => import('./Widget')),
  label: 'Tree++',
  variables: configOptions,
}
