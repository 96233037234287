import React from 'react'

export const configOptions = [
  {
    name: 'query',
    type: 'yasgui',
    label: 'Query that expects ?xStart ?xEnd ?color; xEnd is normalised with the largest xEnd',
  },
  {
    name: 'backgroundColor',
    type: 'text',
    label: 'background-color (css-value); example: #ff0adf',
  },
  {
    name: 'barWidth',
    type: 'text',
    label: 'width of bar (css-value); example: `12px`',
  },
  {
    name: 'gridUnit',
    type: 'text',
    label: 'grid unit, try it out, should be a number'
  }
]

export const barsDescription = {
  name: 'Bars',
  component: React.lazy(() => import('./Widget')),
  label: 'Bars',
  variables: configOptions,
}
