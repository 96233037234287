
import { lazy } from 'react';
import PublishUtils from '../../../helpers/PublishUtils';

class BBPMermaidDescriptor {
  

  static getDescription() {
    return {
      name: 'BBMermaid',
      component: lazy(() =>  import('./BBMermaid')),
      label: 'BBPMermaid',
      variables: [
        
        {
          name: 'mermaid',
          type: 'text',
          label:
            'mermaid string',
        }
        
      ],
    }
  }

  
}

export default BBPMermaidDescriptor
