import React from 'react'

export const configOptions = [
  {
    name: 'query',
    type: 'yasgui',
    label: 'Query\n'
         + '- required: ?categoryLabel; ?categoryURI; ?choiceLabel; ?choiceValue; ?choiceImgURL;\n'
         + '- optional ?categoryChoice ?categoryActionIconColors (semi-colon separated);\n'
         + '- optional ?choice_MODIFIER where MODIFIER cardBorder cardColor cardBackgroundColor checkboxColor checkboxBackgroundColor ribbonIcon;',
  },
  {
    name: 'selectionRule',
    type: 'text',
    label: 'rule triggered when updating selection',
  },
  {
    name: 'selectionRuleParamsJSON',
    type: 'json-text',
    label: 'parameters for the rulechain, for example {"graph":"{{graph}}"}',
  },
  {
    name: 'categoryButtons',
    type: 'json',
    label: 'format: `[{"icon": "fa-star", "label": "say hi", "actions": [{"type": "publish", "topic": "hi", "value": "{{?categoryURI}}"}, ...]}, ...]`',
  },
  {
    name: 'topicToEnableVisuals',
    type: 'text',
    label: 'topic to listen and broadcast to enable visuals',
  },
  {
    name: 'disableVisualsByDefault',
    type: 'boolean',
    label: 'disable visuals by default',
  },
  {
    name: 'iconsAfterCategoryName',
    type: 'boolean',
    label: 'position category name before icon-actions',
  },
  {
    name: 'actionIconSize',
    type: 'text',
    label: 'size of icons for action buttons (css-value); example: `30px`',
  },
  {
    name: 'textLoading',
    type: 'text',
    label: 'text when loading',
  },
  {
    name: 'textNoResults',
    type: 'text',
    label: 'text when no results',
  },
  {
    name: 'textErrorLoading',
    type: 'text',
    label: 'text when error loading results',
  },
  {
    name: 'cardBorder',
    type: 'text',
    label: 'card border (css-value); example: `1px solid black`',
  },
  {
    name: 'cardColor',
    type: 'text',
    label: 'card text-color (css-value); example: `white`',
  },
  {
    name: 'cardBackgroundColor',
    type: 'text',
    label: 'card background-color (css-value); example: `green`',
  },
  {
    name: 'checkboxColor',
    type: 'text',
    label: 'checkbox color (css-value); example: `blue`',
  },
  {
    name: 'checkboxBackgroundColor',
    type: 'text',
    label: 'checkbox background-color (css-value); example: #ff0adf',
  },
  {
    name: 'selectedCardBorder',
    type: 'text',
    label: 'selected card border (css-value); example: `1px solid black`',
  },
  {
    name: 'selectedCardColor',
    type: 'text',
    label: 'selected card text-color (css-value); example: `white`',
  },
  {
    name: 'selectedCardBackgroundColor',
    type: 'text',
    label: 'selected card background-color (css-value); example: `green`',
  },
  {
    name: 'selectedCheckboxColor',
    type: 'text',
    label: 'selected checkbox color (css-value); example: `blue`',
  },
  {
    name: 'selectedCheckboxBackgroundColor',
    type: 'text',
    label: 'selected checkbox background-color (css-value); example: #ff0adf',
  },
  {
    name: 'ribbonColor',
    type: 'text',
    label: 'ribbon color (css-value); example: `blue`',
  },
  {
    name: 'ribbonIconColor',
    type: 'text',
    label: 'ribbon icon-color (css-value); example: #ff0adf',
  },
  {
    name: 'actionsContainerPaddingX',
    type: 'text',
    label: 'actions-container horizontal padding (css-value); example: 10px',
  },
  {
    name: 'actionsContainerPaddingY',
    type: 'text',
    label: 'actions-container vertical padding (css-value); example: 2px',
  },
  {
    name: 'actionButtonsGap',
    type: 'text',
    label: 'gap between action-buttons (css-value); example: 6px',
  },

]

export const visualChoicesDescription = {
  name: 'Visual Choices',
  component: React.lazy(() => import('./Widget')),
  label: 'Visual Choices',
  variables: configOptions,
}
