import React from 'react'
import PublishUtils from '../../../helpers/PublishUtils'

export const configOptions = [
  {
    name: 'command',
    type: 'text',
    label: 'command for server  {validateGarantie,uploadAnyXLSX,uploadAnyFile:ruletag}',
  },
  {
    name: 'ruleParams',
    type: 'json',
    label: 'parameters for the rulechain, for example {"graph":"{{graph}}"}',
  },
  PublishUtils.getTimestampParametersDef(),
  {
    name: 'messageBrowserPermissionsRequired',
    type: 'text',
    label: 'message for user when their browser needs to given rights to access their camera',
  },
  {
    name: 'messageSitePermissionsRequired',
    type: 'text',
    label: 'message for user when they need to give their browser rights to access their camera',
  },
  {
    name: 'messageAllCamerasOccupied',
    type: 'text',
    label: 'message for user when all their cameras are used by other applications',
  },
] as const

export const pictureTakerDescription = {
  name: 'PictureTaker',
  component: React.lazy(() => import('./Widget' )),
  label: 'PictureTaker',
  variables: configOptions,
}
