import React from 'react'

export const configOptions = [
  {
    name: 'splitType',
    type: 'select',
    label: 'split-type',
    options: [
      {label: 'rows', value: 'vertical'},
      {label: 'columns', value: 'horizontal'},
    ],
  },
  {
    name: 'withVisibleHandle',
    type: 'boolean',
    label: 'with visible area-adjustment-handles',
  },
  {
    name: 'splitProportions',
    type: 'array',
    label: 'split-proportions, comma-separated'
  },
  {
    name: 'styles',
    type: 'json',
    label: 'styles format-example: `[{"paddingBottom": "2px", "backgroundColor": "blue"}, "paddingTop": "3px"}]`',
  },
  {
    name: 'areas',
    type: 'json',
    label: 'area definitions',
  },
]

export const SplitLayoutPlusPlusDescription = {
  name: 'SplitLayout++',
  component: React.lazy(() => import('./Widget')),
  label: 'SplitLayout++',
  variables: configOptions,
}
